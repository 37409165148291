const url = "/queries-servi/file/jurisdictional";
const urlFile = "/jurisdiccional-servi/upload-solip-file";
const urlCodeSolip = "/jurisdiccional-servi/update-solicitude";
const urlGetSolicitude = "/jurisdiccional-servi/get-solicitude";
const urlGetsmmlv = "/jurisdiccional-servi/smmlv";
const urlGenerateSolipReport = "/queries-servi/file/generate_solip_report";
const urlUpdateClassification = "/jurisdiccional-servi/update-classification";
const urlRequestReloadRadicadoSolipAnnex = `/jurisdiccional-servi/retry-solip-annexes`;   


export default {
    getJurisdictionalFileURL(signature, axios, form, query){
        let sign = signature(form);
        return axios
                .post(url + query, form, {
                    headers: { "X-SFC-Signature": sign },
                })
                .then((response) => ({ data: response.data }))
                .catch((error) => ({ error: error?.response?.data }));
    },
    postComplaintClassification(signature, axios, form, query){
        let sign = signature(form);
        return axios
                .post(urlGenerateSolipReport + query, form, {
                    headers: { "X-SFC-Signature": sign },
                })
                .then((response) => ({ data: response.data }))
                .catch((error) => ({ error: error?.response?.data }));
    },
    updateClassification(signature, axios, form, query){
        let sign = signature(form);
        return axios
                .put(urlUpdateClassification + query, form, {
                    headers: { "X-SFC-Signature": sign },
                })
                .then((response) => ({ data: response.data }))
                .catch((error) => ({ error: error?.response?.data }));
    },
    getDetailSolicitude(axios, id){
        return axios.get(`${urlGetSolicitude}?id=${id}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    getsmmlv(axios){
        return axios.get(`${urlGetsmmlv}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    setCodeSOLIP(signature, axios, code, form){
        let sign = signature(form);
        return axios
                .patch(urlCodeSolip + '?id=' + code, form, {
                    headers: { "X-SFC-Signature": sign },
                })
                .then((response) => ({ data: response.data }))
                .catch((error) => ({ error: error?.response?.data }));
    },
    setAnexosSOLIP(signature, axios, files) {
        let formData = new FormData();
        files.forEach((file, index) => {
          formData.append(`file`, file);
        });
        return axios
          .post(urlFile, formData, {
            headers: { "X-SFC-Signature": signature({}) },
          })
          .then((response) => ({ data: response.data }))
          .catch((error) => ({ error: error?.response?.data }));
      },
    postReloadRadicadoSolipAnnex(signature, axios, form, query){
        let sign = signature(form);
        return axios
                .post(urlRequestReloadRadicadoSolipAnnex + query, form, {
                    headers: { "X-SFC-Signature": sign },
                })
                .then((response) => ({ data: response.data }))
                .catch((error) => ({ error: error?.response?.data }));
    },
}