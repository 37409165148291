export default [
  //#region Reports
  {
    path: "fco/reports",
    component: () => import("@/views/dashboard/fco/design/DesignInformDCF"),
    meta: {
      title: "Informes",
      breadcrumb: [{ label: "Inicio", link: "/" }, { label: "Informes" }],
    },
  },
  {
    path: "fco/report/view",
    name: "reportView",
    component: () => import("@/views/dashboard/fco/reports/ViewHistoryReport"),
    meta: {
      title: "Ver Histórico de Informe",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes" },
        { label: "Ver informe" },
      ],
    },
  },
  {
    path: "fco/reports/edit",
    component: () => import("@/views/dashboard/fco/reports/ReportFormSteps"),
    meta: {
      title: "Editar Informe",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes" },
        { label: "Ver informe" },
      ],
    },
  },
  {
    path: "fco/new_report",
    name: "reportForm",
    props: true,
    component: () => import("@/views/dashboard/fco/design/DesignInformDCF"),
    meta: {
      title: "Informes por entidad",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes", link: "dashboard/fco/reports" },
        { label: "Nuevo informe" },
      ],
    },
  },
  {
    path: "fco/new_report_steps",
    name: "reportFormSteps",
    props: true,
    component: () => import("@/views/dashboard/fco/reports/ReportFormSteps"),
    meta: {
      title: "Informes por entidad",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes", link: "dashboard/fco/reports" },
        { label: "Nuevo informe", link: "dashboard/fco/new_report" },
        { label: "Pasos" },
      ],
    },
  },
  {
    path: "fco/company_report",
    component: () => import("@/views/dashboard/fco/reports/CompanyReport"),
    meta: {
      title: "Informe por entidad",
      breadcrumb: [{ label: "Inicio", link: "/" }, { label: "Informes" }],
    },
  },
  {
    path: "fco/company_report/view",
    name: "companyReportView",
    component: () =>
      import("@/views/dashboard/fco/reports/CompanyReportDetail"),
    meta: {
      title: "Ver Histórico Informe",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes" },
        { label: "Ver informe" },
      ],
    },
  },
  {
    path: "fco/company_report/recommendation",
    name: "recommendationsCompanyView",
    component: () =>
      import("@/views/dashboard/fco/recommendations/ViewRecommendation"),
    meta: {
      title: "Detalle recomendación",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes", link: "dashboard/fco/company_report" },
        { label: "Recomendación" },
      ],
    },
  },
  {
    path: "fco/reports/new_report/finish",
    name: "endReport",
    component: () => import("@/views/dashboard/fco/reports/EndReport"),
    meta: {
      title: "Nuevo informe",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Informes", link: "dashboard/fco/reports" },
        { label: "Nuevo informe", link: "dashboard/fco/new_report" },
        { label: "Informe guardado" },
      ],
    },
  },
  //#endregion

  //#region Recommendations
  {
    path: "fco/clasification-demand",
    name: "clasification-demand",
    component: () => import("@/views/dashboard/fco/demand/Clasification"),
    meta: {
      title: "Clasificación de quejas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Conciliaciones y demandas", link: "/" },
        {
          label: "Clasificación",
          link: "dashboard/fco/clasification-demand",
        },
      ],
    },
  },
  // Derechos de petición.
  {
    path: "classification/right_request",
    name: "clasification-main",
    component: () => import("@/feature/clasification/presentation/views/clasificaction"),
    meta: {
      title: "Derechos de petición",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Derechos de petición", link: "/" },
      ],
    },
  },
  {
    path: "classification/right_request/detail/:id",
    name: "clasification-detail",
    component: () => import("@/feature/clasification/presentation/views/detail"),
    meta: {
      title: "Derechos de petición",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Derechos de petición", link: "dashboard/classification/right_request" },
        {
          label: "Detalle de la queja",
          link: "#",
        },
      ],
    },
  },
  {
    path: "fco/clasification-demand/:id",
    name: "clasification-demand-detail",
    component: () => import("@/views/dashboard/fco/demand/ClasificationDetail"),
    meta: {
      title: "Clasificación de quejas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Conciliaciones y demandas", link: "/" },
        {
          label: "Clasificación",
          link: "dashboard/fco/clasification-demand",
        },
      ],
    },
  },
  {
    path: "fco/management-demand",
    name: "management-demand",
    component: () => import("@/views/dashboard/fco/demand/SolipManagement"),
    meta: {
      title: "Gestión de conciliaciones y demandas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Conciliaciones y demandas", link: "/" },
        {
          label: "Gestión",
          link: "dashboard/fco/management-demand",
        },
      ],
    },
  },
  /*in case of need for reactivation this view{
    path: "fco/management-demand",
    name: "management-demand",
    component: () => import("@/views/dashboard/fco/demand/Management"),
    meta: {
      title: "Gestión de conciliaciones y demandas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Conciliaciones y demandas", link: "/" },
        {
          label: "Gestión",
          link: "dashboard/fco/management-demand",
        },
      ],
    },
  },*/
  {
    path: "fco/historical-demand",
    name: "historical-demand",
    component: () => import("@/views/dashboard/fco/demand/Historical"),
    meta: {
      title: "Histórico de conciliaciones y demandas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Conciliaciones y demandas", link: "/" },
        {
          label: "Histórico",
          link: "dashboard/fco/historical-demand",
        },
      ],
    },
  },
  {
    path: "fco/solicitude/:id",
    name: "soliditude-detail",
    component: () =>
      import("@/views/dashboard/fco/demand/SolicitudeDetailForm"),
    meta: {
      title: "Histórico de conciliaciones y demandas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Conciliaciones y demandas", link: "/" },
        {
          label: "Histórico",
          link: "dashboard/fco/historical-demand",
        },
      ],
    },
  },
  {
    path: "fco/recommendations-dcf",
    name: "recommendations-dcf",
    component: () => import("@/views/dashboard/fco/design/DesignDCF"),
    meta: {
      title: "Recomendaciones y vocerías",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations-dcf",
        },
      ],
    },
  },
  {
    path: "fco/recommendations-ev",
    name: "recommendations-ev",
    component: () => import("@/views/dashboard/fco/design/DesignEV"),
    meta: {
      title: "Recomendaciones y vocerías",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Entidad vigilada", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations-ev",
        },
      ],
    },
  },
  {
    path: "fco/informe-ev",
    name: "informe-ev",
    component: () => import("@/views/dashboard/fco/design/DesignInformEV"),
    meta: {
      title: "Informes anuales EV",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Entidad vigilada", link: "/" },
        {
          label: "Informes anuales EV",
          link: "dashboard/fco/informe-ev",
        },
      ],
    },
  },
  // Habeas data - url v2
  {
    path: "classification/habeas_data",
    name: "registro-informe-anual",
    component: () => import("@/feature/heabeas_data/presentation/views/habeas-data"),
    meta: {
      title: "Habeas data",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Habeas data",
          link: "/",
        },
      ],
    },
  },
  // Habeas data, detalle - url v2
  {
    path: "classification/habeas_data/detail/:id",
    name: "registro-informe-anual",
    component: () => import("@/feature/heabeas_data/presentation/views/detail"),
    meta: {
      title: "Habeas data",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Habeas data",
          link: "dashboard/classification/habeas_data",
        },
        { label: "Detalle habeas data", link: "#" },
      ],
    },
  },
  {
    path: "fco/registro-informe-anual",
    name: "registro-informe-anual",
    component: () => import("@/views/dashboard/fco/registrationAnnualReport/"),
    meta: {
      title: "Registro Informe Anual",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
        {
          label: "Registro Informe Anual",
          link: "dashboard/fco/registro-informe-anual",
        },
      ],
    },
  },
  {
    path: "annual-report",
    name: "annual-report-list",
    component: () =>
      import("@/feature/annual_report/presentation/views/annual_report"),
    meta: {
      title: "Informes anuales DCF",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
      ],
    },
  },
  {
    path: "annual-report/create/",
    name: "annual-report-create",
    component: () =>
      import(
        "@/feature/annual_report/presentation/views/steps/create_update_ar"
      ),
    meta: {
      title: "Nuevo informe anual DCF",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/annual-report" },
        {
          label: "Informes de gestión",
          link: "dashboard/annual-report",
        },
      ],
    },
  },
  {
    path: "annual-report/detail/:id",
    name: "annual-report-detail",
    component: () =>
      import("@/feature/annual_report/presentation/views/detail_annual_report"),
    meta: {
      title: "Informes anuales DCF",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
        {
          label: "Informes de gestión",
          link: "dashboard/fco/informe-dcf",
        },
      ],
    },
  },
  {
    path: "annual-report/detail/:id/:showStepDetail",
    name: "annual-report-detail-step",
    component: () =>
      import(
        "@/feature/annual_report/presentation/views/steps/manage_steps_ar"
      ),
    meta: {
      title: "Informes anuales DCF",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
        {
          label: "Informes de gestión",
          link: "dashboard/fco/informe-dcf",
        },
      ],
    },
  },
  {
    path: "annual-report/edit/:id/",
    name: "annual-report-edit",
    component: () =>
      import(
        "@/feature/annual_report/presentation/views/steps/manage_steps_ar"
      ),
    meta: {
      title: "Nuevo informe anual DCF",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
        {
          label: "Informes de gestión",
          link: "dashboard/fco/informe-dcf",
        },
      ],
    },
  },
  {
    path: "register-annual-report",
    name: "register-annual-report-list",
    component: () =>
      import("@/feature/annual_report_ev/presentation/views/annual_report"),
    meta: {
      title: "Registro Informe Anual",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Defensor del consumidor financiero",
          link: "/dashboard/register-annual-report",
        },
        {
          label: "Registro Informe Anual",
          link: "#",
        },
      ],
    },
  },
  {
    path: "register-annual-report/manage/:id",
    name: "register-annual-report-manage",
    component: () =>
      import("@/feature/annual_report_ev/presentation/views/create"),
    meta: {
      title: "Registro Informe Anual",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor financiero", link: "#" },
        // { label: "Registro informe anual", link: "/register-annual-report" },
        { label: "Paso 1", link: "#" },
      ],
    },
  },
  {
    path: "register-annual-report/detail/:id",
    name: "register-annual-report-detail",
    component: () =>
      import("@/feature/annual_report_ev/presentation/views/detail"),
    meta: {
      title: "Registro Informe Anual",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor financiero", link: "#" },
        // { label: "Registro informe anual", link: "/register-annual-report" },
        { label: "Paso 1", link: "#" },
      ],
    },
  },
  {
    path: "register-annual-report/edit/:id",
    name: "register-annual-report-edit",
    component: () =>
      import("@/feature/annual_report_ev/presentation/views/edit"),
    meta: {
      title: "Registro Informe Anual",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor financiero", link: "#" },
        // { label: "Registro informe anual", link: "/register-annual-report" },
        { label: "Paso 1", link: "#" },
      ],
    },
  },
  {
    path: "fco/informe-dcf",
    name: "informe-dcf",
    component: () => import("@/views/dashboard/fco/design/DesignInformDCF"),
    meta: {
      title: "Informes anuales DCF",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Defensor del consumidor", link: "/" },
        {
          label: "Informes anuales DCF",
          link: "dashboard/fco/informe-dcf",
        },
      ],
    },
  },
  {
    path: "fco/recommendationsHello",
    name: "recommendations",
    component: () =>
      import("@/views/dashboard/fco/recommendations/Recommendations"),
    meta: {
      title: "Recomendaciones y vocerías",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: " ",
          link: "dashboard/fco/recommendations",
        },
      ],
    },
  },
  {
    path: "fco/recommendations/view",
    name: "recommendationsView",
    component: () =>
      import("@/views/dashboard/fco/recommendations/ViewRecommendation"),
    meta: {
      title: "Ver recomendación",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations",
        },
        { label: "Recomendación" },
      ],
    },
  },
  {
    path: "fco/recommendations/tracking",
    name: "recommendationsTracking",
    component: () =>
      import("@/views/dashboard/fco/recommendations/TrackingRecommendation"),
    meta: {
      title: "Ver recomendación",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations",
        },
        { label: "Recomendación" },
      ],
    },
  },
  {
    path: "fco/recommendations/edit",
    name: "recommendationsFormSteps",
    component: () =>
      import("@/views/dashboard/fco/recommendations/RecommendationsFormSteps"),
    meta: {
      title: "Editar Recomendaciónes y Vocerías",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations",
        },
        { label: "Editar recomendaciones y vocerías" },
      ],
    },
  },
  {
    path: "fco/new_recommendation",
    name: "recommendationForm",
    component: () =>
      import("@/views/dashboard/fco/recommendations/RecommendationForm"),
    meta: {
      title: "Nueva Recomendación",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations",
        },
        { label: "Nueva recomendación" },
      ],
    },
  },
  {
    path: "fco/recommendations/new_recommendations_steps",
    props: true,
    component: () =>
      import("@/views/dashboard/fco/recommendations/RecommendationsFormSteps"),
    meta: {
      title: "Recomendaciones y Vocerías",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations",
        },
        {
          label: "Nueva recomendación",
          link: "dashboard/fco/new_recommendation",
        },
        { label: "Pasos" },
      ],
    },
  },
  {
    path: "fco/recommendations/new_recommendation/finish",
    name: "endRecommendation",
    component: () =>
      import("@/views/dashboard/fco/recommendations/EndRecommendation"),
    meta: {
      title: "Nueva Recomendación",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Recomendaciones y vocerías",
          link: "dashboard/fco/recommendations",
        },
        {
          label: "Nueva recomendación",
          link: "dashboard/fco/new_recommendation",
        },
        { label: "Recomendación registrada" },
      ],
    },
  },
  //#endregion
];
