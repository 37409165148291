<template>
  <div :class="advanceStyles">
    <label :for="id" class="w-full text-xs md:text-xs text-gray-500 text-opacity-80 text-justify"
      :class="{ 'text-red-500': invalid }"><b>{{ label }}</b><span v-if="required" class="text-red-500">*</span>
      <p class="text-xs text-gray-400" v-if="!disabled">
        (Máximo {{ maxlength }} caracteres)
      </p>
    </label>
    <a-textarea class="w-full mt-2 border rounded-lg text-xs md:text-sm resize-none" :name="id" :placeholder="placeholder"
      :value="value" @input="onInput" @keyup="checkInput" :maxLength="maxlongitud" :rows="totalRows" :showCount="true"
      :disabled="disabled"></a-textarea>
    <p v-if="invalid" class="mt-2 text-xs text-red-500">
      {{ feedback }}
    </p>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "type",
    "label",
    "placeholder",
    "value",
    "disabled",
    "danger",
    "required",
    "maxlength",
    "minlength",
    "rows",
    "styles",
  ],
  data() {
    return {
      advanceStyles: "h-full w-full mt-5 text-left flex flex-col " + this.styles,
      invalid: false,
      feedback: "",
      newMaxLength: 0,
    };
  },
  methods: {
    checkInput() {
      if (this.value === "" && this.required) {
        this.invalid = true;
        this.feedback = "Este campo es obligatorio";
      } else if (this.minlength && this.value.length <= this.minlength) {
        this.invalid = true;
        this.feedback = `Este campo debe tener mas de ${this.minlength} caracteres`;
      } else {
        this.invalid = false;
        this.feedback = "";
      }
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = Boolean(invalid);
      }
      return Boolean(invalid);
    },
    validatedInput(input) {
      let newlines = input.split("\n");
      this.newMaxLength = (newlines.length - 1) * 3;
      if (input.length > this.maxlongitud) {
        return input.substring(0, this.maxlongitud);
      }
      return input;
    },
    onInput(event) {
      this.$emit("input", this.validatedInput(event.target.value));
    },
  },
  computed: {
    classObject: function () {
      let classes = ["border-gray-300", "placeholder-gray-400"];
      if (this.danger || this.invalid) {
        classes = ["border-red-500", "placeholder-red-500"];
      }
      return classes;
    },
    maxlongitud: function () {
      return this.$props.maxlength
        ? +this.$props.maxlength - this.newMaxLength
        : 1000;
    },
    totalRows: function () {
      return this.rows ? this.rows : 6;
    },
  },
};
</script>