import Axios from "axios";
import store from "../../store";
import { signature } from "../signature";
import alertsModule from "./modules/alerts.module";
import loginModule from "./modules/login.module";
import userModule from "./modules/user.module";
import passwordModule from "./modules/password.module";
import complaintModule from "./modules/complaint.module";
import jurisdiccionalModule from "./modules/jurisdiccional.module";
import moduleModule from "./modules/module.module";

// Administrative modules
import companyListModule from "./modules/company_list.module";
import companyModule from "./modules/company.module";
import complaintDetailModule from "./modules/complaint_detail.module";
import complaintHistoricalModule from "./modules/complaint_historical.module";
import complaintManagementModule from "./modules/complaint_management.module";
import complaintSampleModule from "./modules/complaint_sample.module";
import fcoModule from "./modules/fco.module";
import annualReportModule from "./modules/annual_report.module";
import fco_recommendationsModule from "./modules/fco_recommendations.module";
import personModule from "./modules/person.module";
import quejaallModule from "./modules/quejaall.module";
import quejaallModuleFile from "./modules/quejaallfile.module";
import attachmentListModule from "./modules/attachment_list.module";
import attachmentUrlModule from "./modules/attachment_url.module";
import reproductivityModule from "./modules/reproductivity.module";
import roleModule from "./modules/role.module";
import usersManagementModule from "./modules/usersmanagement.module";
import confirmCodeModule from "./modules/confirmcode.module";
import complaintMonitoringModule from "./modules/complaint_monitoring.module";
import dashboardModule from "./modules/dashboard.module";
import directionModule from "./modules/directions.module";
import modulesModule from "./modules/modules.module";
import duplicityModule from "./modules/duplicate_complaints.module";
import ratesModule from "./modules/rates.module";
import formModule from "./modules/form.module";
import { get } from "lodash";
// import pdfile from "./modules/pdfdownload.module";

let axios = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api",
});

// TO_DO: delete to product
let axiosForm = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api",
});

// TO_DO: delete to product
axiosForm.defaults.headers.common["X-SFC-Origin"] =
  process.env.NODE_ENV == "development" ? "desarrollo" : "application";

axios.defaults.headers.common["X-SFC-Origin"] =
  process.env.NODE_ENV == "development" ? "desarrollo" : "application";

const api = {
  // Base URL
  defaults: {
    baseURL: process.env.VUE_APP_BASE_URL,
  },
  // login
  login: (credentials) => {
    return loginModule.login(signature, axios, credentials);
  },
  logout: () => {
    if (store.state.session.user.email) {
      return loginModule.logout(signature, axios, {
        username: store.state.session.user.email,
      });
    } else {
      return loginModule.logout(signature, axios, {
        username: store.state.session.user.username,
      });
    }
  },
  // /user
  signup: (form, files) => {
    return userModule.signup(signature, axios, form, files);
  },
  getUser: (id) => {
    return userModule.getUser(axios, id);
  },
  updateUserPassword: (id, user) => {
    return userModule.updateUserPassword(signature, axios, id, user);
  },
  updateUserProfile: (id, user) => {
    return userModule.updateUserProfile(signature, axios, id, user);
  },
  // password
  sendPasswordCode: (email) => {
    return passwordModule.sendPasswordCode(signature, axios, email);
  },
  resendPasswordCode: (email) => {
    return passwordModule.resendPasswordCode(signature, axios, email);
  },
  changePassword: (form) => {
    return passwordModule.changePassword(signature, axios, form);
  },
  // /queja
  getComplaints: () => {
    return complaintModule.getComplaints(axios);
  },
  getComplaintById: (id) => {
    return complaintModule.getComplaintById(axios, id);
  },
  getComplaintsByPage: (query) =>
    complaintModule.getComplaintsByPage(axios, query),
  setComplaint(form, files) {
    return complaintModule.setComplaint(signature, axios, form, files);
  },
  setSolicitude(form, files) {
    return complaintModule.setSolicitude(signature, axios, form, files);
  },
  getJurisdictionalFileURL(form, query) {
    return jurisdiccionalModule.getJurisdictionalFileURL(
      signature,
      axios,
      form,
      query
    );
  },
  postComplaintClassification(form, query) {
    return jurisdiccionalModule.postComplaintClassification(
      signature,
      axios,
      form,
      query
    );
  },
  postReloadRadicadoSolipAnnex(form, query = '') {
    return jurisdiccionalModule.postReloadRadicadoSolipAnnex(
      signature,
      axios,
      form,
      query
    );
  },
  updateClassification(form, query) {
    return jurisdiccionalModule.updateClassification(
      signature,
      axios,
      form,
      query
    );
  },
  desistComplaint(id, form) {
    return complaintModule.desistComplaint(signature, axios, id, form);
  },
  desistComplaintAttach(id, form, files) {
    return complaintModule.desistComplaintAttach(
      signature,
      axios,
      id,
      form,
      files
    );
  },
  setReplica(form, files) {
    return complaintModule.setReplica(signature, axios, form, files);
  },
  setCalification(form) {
    return complaintModule.setCalification(signature, axios, form);
  },
  getComplaintsNoRated() {
    return complaintModule.getComplaintsNoRated(axios);
  },
  setAnexos(form, files) {
    return complaintModule.setAnexos(signature, axios, form, files);
  },
  setCodeSOLIP(code, form) {
    return jurisdiccionalModule.setCodeSOLIP(signature, axios, code, form);
  },
  setAnexosSOLIP(files) {
    return jurisdiccionalModule.setAnexosSOLIP(signature, axios, files);
  },
  checkComplaint(id) {
    return complaintModule.checkComplaint(axios, id);
  },
  getComplaintQuery: (query) => complaintModule.getComplaintQuery(axios, query),
  // /module
  getModules: () => {
    return moduleModule.getModules(axios);
  },
  // Administrative services
  // Alerts
  getAlerts: (query) => alertsModule.getAlerts(axios, query),
  getAlertTypes: (query) => alertsModule.getAlertTypes(axios, query),
  getNotViewdAlert: () => alertsModule.getNotViewdAlert(axios),  
  getAlertSettings: (query) => alertsModule.getAlertSettings(axios, query),
  setAlertSettings: (alert_configuration, configuration_id) =>
    alertsModule.setAlertSettings(
      axios,
      signature,
      alert_configuration,
      configuration_id
    ),
  setReadAlert: (alert) => alertsModule.setReadAlert(axios, signature, alert),
  setStateSetting: (data) =>
    alertsModule.setStateSetting(axios, signature, data),
  //Alerts - NotificationsEv
  getNotificationsEv: (query) => alertsModule.getNotificationsEv(axios, query),
  getNotificationTypes: (query) => alertsModule.getNotificationTypes(axios, query),
  getNotViewdAlertEv: () => alertsModule.getNotViewdAlertEv(axios),
  getStatusTypes: (query) => alertsModule.getStatusTypes(axios, query),
  setReadNotificationEv: (alert) => alertsModule.setReadNotificationEv(axios, signature, alert), 
  setReadAlertEv: (alert) => alertsModule.setReadAlertEv(axios, signature, alert),
  // Companies
  createCompany: (data) =>
    companyListModule.createCompany(axios, signature, data),
  readCompany: (query) => companyListModule.readCompany(axios, query),
  getCompany: (id) => companyListModule.getCompany(axios, id),
  updateCompany: (data, id) =>
    companyListModule.updateCompany(axios, signature, data, id),
  changeStateCompany: (data, id) =>
    companyListModule.changeStateCompany(axios, signature, data, id),
  inactivateMassiveCompanys: (data) =>
    companyModule.inactivateMassiveCompanys(axios, signature, data),
  createCompanyExcel: (data) =>
    companyModule.createCompanyExcel(axios, signature, data),

  // detail complaint
  getDetailComplaint: (id) =>
    complaintDetailModule.getDetailComplaint(axios, id),
  // detail Solicitude
  getDetailSolicitude: (id) =>
    jurisdiccionalModule.getDetailSolicitude(axios, id),
  getsmmlv: (id) => jurisdiccionalModule.getsmmlv(axios),
  //FCO

  getReports: (query) => fcoModule.getReports(axios, query),
  //TO_DO change axionForm for axios
  getCounters: (data,query) => fcoModule.getCounters(axios, query, signature, data),
  getReportsV2: (query, DocID = "") =>
    fcoModule.getReportsV2(axios, query, DocID),
  getAnualReport: (query, DocID = "") =>
    fcoModule.getAnualReport(axios, query, DocID),
  getAnualReportV2: (query, DocID = "") =>
    annualReportModule.getAnualReport(axios, query, DocID),
  createReport: (reportForm) =>
    fcoModule.createReport(axios, signature, reportForm),
  createReportDetail: (reportDetail) =>
    fcoModule.createReportDetail(axios, signature, reportDetail),
  //TO_DO change axionForm for axios
  createAnualReport: (dataReport) =>
    fcoModule.createAnualReport(axios, signature, dataReport),
  createEVAnualReport: (dataReport) =>
    fcoModule.createAnualEVReport(axios, signature, dataReport),
  updateAnualEVReport: (dataReport, id) =>
      fcoModule.createAnualEVReport(axios, signature, dataReport, id),
     //TO_DO change axionForm for axios
  createAnexo: (dataAnexo) =>
  fcoModule.createAnexo(axios, signature, dataAnexo),
  updateAnualReport: (dataReport,id) =>
    fcoModule.updateAnualReport(axios, signature, dataReport, id),
  createSectionReport: (sectionForm) =>
    fcoModule.createSectionReport(axios, signature, sectionForm),
  createIncidentReport: (incidentForm) =>
    fcoModule.createIncidentReport(axios, signature, incidentForm),
  createAttention: (attentionForm) =>
    fcoModule.createAttention(axios, signature, attentionForm),
  getStepReport: (id, step) => fcoModule.getStepReport(axios, id, step),
  createNewMeet: (newMeet) =>
    fcoModule.createNewMeet(axios, signature, newMeet),
  createCompanyReport: (companyReportForm, files) =>
    fcoModule.createCompanyReport(axios, signature, companyReportForm, files),
  getCompanyReport: () => fcoModule.getCompanyReport(axios),
  setFinishReport: (reportId, obj) =>
    fcoModule.setFinishReport(axios, signature, reportId, obj),

  //Recommendations

  createRecommendation: (data) =>
    fco_recommendationsModule.createRecommendation(axios, signature, data),
  recommendationStep1: (recommendation_id, step, form) =>
    fco_recommendationsModule.recommendationStep1(
      axios,
      signature,
      recommendation_id,
      step,
      form
    ),
  recommendationSteps: (recommendation_id, step, form, step4Id = null) =>
    fco_recommendationsModule.recommendationSteps(
      axios,
      signature,
      recommendation_id,
      step,
      form,
      step4Id
    ),
  addActionPlan: (recommendation_id, actionPlan) =>
    fco_recommendationsModule.addActionPlan(
      axios,
      signature,
      recommendation_id,
      actionPlan
    ),
  addFilesToActionPlan: (
    recommendation_id,
    actionPlanId,
    typeFile,
    actionPlan
  ) =>
    fco_recommendationsModule.addFilesToActionPlan(
      axios,
      signature,
      recommendation_id,
      actionPlanId,
      typeFile,
      actionPlan
    ),
  getActionPlans: (recommendation_id) =>
    fco_recommendationsModule.getActionPlans(
      axios,
      signature,
      recommendation_id
    ),
  progressReport: (recommendation_id, progressReport) =>
    fco_recommendationsModule.progressReport(
      axios,
      signature,
      recommendation_id,
      progressReport
    ),
  addFilesToProgressReport: (
    recommendation_id,
    progressReportId,
    typeFile,
    progressReport
  ) =>
    fco_recommendationsModule.addFilesToProgressReport(
      axios,
      signature,
      recommendation_id,
      progressReportId,
      typeFile,
      progressReport
    ),
  tracingsRecommendations: (recommendation_id, tracingsRecommendations) =>
    fco_recommendationsModule.tracingsRecommendations(
      axios,
      signature,
      recommendation_id,
      tracingsRecommendations
    ),
  entityAnswer: (recommendation_id, answer) =>
    fco_recommendationsModule.entityAnswer(
      axios,
      signature,
      recommendation_id,
      answer
    ),
  closeRecommendation: (recommendation_id, closeForm) =>
    fco_recommendationsModule.closeRecommendation(
      axios,
      signature,
      recommendation_id,
      closeForm
    ),
  finishRecommendation: (recommendation_id) =>
    fco_recommendationsModule.finishRecommendation(
      axios,
      signature,
      recommendation_id
    ),
  getResource: (query = "") =>
    fco_recommendationsModule.getResource(axios, query),
  getRecommendations: (query = "") =>
    fco_recommendationsModule.getRecommendations(axios, query),
  getRecommendationsStatus: (query = "") =>
    fco_recommendationsModule.getRecommendationsStatus(axios, query),
  getStep0: (annual_report_id) =>
    fco_recommendationsModule.getStep0(axios, annual_report_id),
  getSteps: (recommendation, step) =>
    fco_recommendationsModule.getSteps(axios, recommendation, step),
  getActionPlans: (recommendation_id) =>
    fco_recommendationsModule.getActionPlans(axios, recommendation_id),
  getProgressReports: (recommendation_id) =>
    fco_recommendationsModule.getProgressReports(axios, recommendation_id),
  getTracings: (recommendation_id) =>
    fco_recommendationsModule.getTracings(axios, recommendation_id),
  getEntityAnswer: (recommendation_id) =>
    fco_recommendationsModule.getEntityAnswer(axios, recommendation_id),
  getCloseRecommendation: (recommendation_id) =>
    fco_recommendationsModule.getCloseRecommendation(axios, recommendation_id),
  getRecommendationByCompany: () =>
    fco_recommendationsModule.getRecommendationByCompany(axios),
  // Historical complaint
  getListBigquery: (query) =>
    complaintHistoricalModule.getListBigquery(axios, query),
  getBigqueryComplaint: (complaint, query) =>
    complaintHistoricalModule.getBigqueryComplaint(axios, complaint, query),

  // Management complaint
  saveManagement: (data) =>
    complaintManagementModule.saveManagement(axios, signature, data),
  getManagementComplaint: (query) =>
    complaintManagementModule.getManagementComplaint(axios, query),

  // Sample for complaints
  createSample: (data) =>
    complaintSampleModule.createSample(axios, signature, data),
  readSample: (query) => complaintSampleModule.readSample(axios, query),
  getSample: (id, query) => complaintSampleModule.getSample(axios, id, query),

  // Person
  getDetailUser: (data, id) =>
    personModule.getDetailUser(axios, signature, data, id),
  getQueryPerson: (query) => personModule.getQueryPerson(axios, query),
  getQueryPersonHistory: (query) =>
    personModule.getQueryPersonHistory(axios, query),

  // All complaints
  getComplaintsAll: (query) => quejaallModule.getComplaintsAll(axios, query),
  // All complaints file
  getComplaintsAllFile: (query) =>
    quejaallModuleFile.getComplaintsAllFile(axios, query),

  // getPDFile: () => pdfile.getPDFile(axios),

  // All attachments
  getAttachmentsAll: (query) =>
    attachmentListModule.getAttachmentsAll(axios, query),

  // Attachment URL
  getAttachmentUrl: (query) =>
    attachmentUrlModule.getAttachmentUrl(axios, query),

  getAttachmentUrlByPath: (query) =>
    attachmentUrlModule.getAttachmentUrlByPath(axios, query),

  // Reproductivity
  listReproductivity: (query) =>
    reproductivityModule.listReproductivity(axios, query),

  //All Modules

  getAllModules: () => {
    return modulesModule.getAllModules(axios);
  },

  // Role
  createRole: (data) => roleModule.createRole(axios, signature, data),
  readRole: (query) => roleModule.readRole(axios, query),
  getRole: (id) => roleModule.getRole(axios, id),
  updateRole: (data, id) => roleModule.updateRole(axios, signature, data, id),
  changeStateRole: (data, id) =>
    roleModule.changeStateRole(axios, signature, data, id),

  //Directions
  createDirection: (data) =>
    directionModule.createDirection(axios, signature, data),
  readDirections: (query) => directionModule.readDirections(axios, query),
  getDirection: (id) => directionModule.getDirection(axios, id),
  updateDirection: (id, data) =>
    directionModule.updateDirection(axios, signature, data, id),
  changeStateDirection: (data, id) =>
    directionModule.changeStateDirection(axios, signature, data, id),

  // User management
  createUser: (data) =>
    usersManagementModule.createUser(axios, signature, data),
  readUser: (query) => usersManagementModule.readUser(axios, query),
  getAdminUser: (id) => usersManagementModule.getUser(axios, id),
  updateUser: (data, id) =>
    usersManagementModule.updateUser(axios, signature, data, id),
  inactivateMassiveUsers: (data) =>
    usersManagementModule.inactivateMassiveUsers(axios, signature, data),
  changeStateUser: (data, id) =>
    usersManagementModule.changeStateUser(axios, signature, data, id),
  //Financial consumer
  getUserFinanConsum: (id) => {
    return usersManagementModule.getUserFinanConsum(axios, id);
  },
  updateUserFinanConsum: (data, id) =>
    usersManagementModule.updateUserFinanConsum(axios, signature, data, id),
  // Verify code
  verifyCode: (form) => confirmCodeModule.verifyCode(axios, signature, form),
  resendCode: (form) => confirmCodeModule.resendCode(axios, signature, form),

  //Rates

  getFilters: () => ratesModule.getFilters(axios),
  getDataForFilterFields: (params) =>
    ratesModule.getDataForFilterFields(axios, params),
  generateReport: (query) =>
    ratesModule.generateReport(axios, signature, query),

  // Monitoring
  getMonitoring: (id) => complaintMonitoringModule.getMonitoring(axios, id),

  // Dashboard
  getColumnsForFilter: () => dashboardModule.getColumnsForFilter(axios),
  getOperationsForType: (data) =>
    dashboardModule.getOperationsForType(axios, data),
  getDataForFilter: (data) => dashboardModule.getDataForFilter(axios, data),
  getProductivity: (name_filter, data) =>
    dashboardModule.getProductivity(axios, signature, name_filter, data),
  applyFilters: (data) => dashboardModule.applyFilters(axios, signature, data),
  getVolumetry: (data) => dashboardModule.getVolumetry(axios, signature, data),
  getSatisfaction: (data) =>
    dashboardModule.getSatisfaction(axios, signature, data),
  getResponseTime: (data) =>
    dashboardModule.getResponseTime(axios, signature, data),
  searchComplaints: (data) =>
    dashboardModule.searchComplaints(axios, signature, data),

  // Duplicate complaint module

  getDuplicateComplaints: (query) =>
    duplicityModule.getDuplicateComplaints(axios, query),
  getDuplicateComplaintsByUser: (query) =>
    duplicityModule.getDuplicateComplaintsByUser(axios, query, signature),
  managementDuplicateComplaints: (idPrincipal, complaints) =>
    duplicityModule.managementDuplicateComplaints(
      axios,
      idPrincipal,
      complaints,
      signature
    ),
  releaseDuplicateComplaints: (complaints) =>
    duplicityModule.releaseDuplicateComplaints(axios, complaints, signature),

  getReceptionOptions: () => {
    return axios
      .get("/receptionpoints/")
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.error.data };
      });
  },

  // TO_DO: change axiosForm for axios
  getFormRequest: (query) => formModule.getFormRequest(axios, query),

  basicFetch: ({ method = "get", url = "", body = {} }) => {
    if (method === "get") {
      return axios
        .get(url)
        .then((response) => {
          return { data: response.data };
        })
        .catch((error) => {
          return { error: error.response.data };
        });
    }
    if (method === "post") {
      return axios
        .post(`${url}`, body, {
          headers: {
            "X-SFC-Signature": signature(body),
          },
        })
        .then((response) => {
          return { data: response.data };
        })
        .catch((error) => {
          return { error: error.response.data };
        });
    }
    if (method === "put") {
      return axios
        .put(`${url}${idPrincipal}/`, body, {
          headers: {
            "X-SFC-Signature": signature(body),
          },
        })
        .then((response) => {
          return { data: response.data };
        })
        .catch((error) => {
          return { error: error.response.data };
        });
    }
  },
};

export default {
  install(Vue) {
    const refreshToken = async () => {
      let refresh = store.state.session.refresh;

      const response = await axios.post(
        "/token/refresh/",
        { refresh },
        {
          headers: { "X-SFC-Signature": Vue.prototype.$signature({ refresh }) },
        }
      );
      if (response.data) {
        store.commit("session/setSessionAccess", response.data.access);
        store.commit("session/setSessionRefresh", response.data.refresh);
      } else {
        await store.dispatch("session/logout");
        window.location.href = "/login";
      }
    };

    //TO_DO: delete this axiosForm
    axiosForm.interceptors.request.use(
      function (config) {
        if (config.method == "get") {
          config.headers["X-SFC-Signature"] = "SFC_5asc4dDf4asf_6Ares";
          config.headers["X-SFC-Invalidate-Signature"] =
            "SFC_5asc4dDf4asf_6Ares";
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        } else if (
          config.method == "post" ||
          config.method == "patch" ||
          config.method == "put"
        ) {
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
      { synchronous: true }
    );

    axios.interceptors.request.use(
      function (config) {
        if (config.method == "get") {
          let url = config.baseURL + config.url;
          let sign = Vue.prototype.$signature(url);
          config.headers["X-SFC-Signature"] = sign;
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        } else if (
          config.method == "post" ||
          config.method == "patch" ||
          config.method == "put"
        ) {
          if (store.getters["session/isAuthenticated"]) {
            config.headers[
              "Authorization"
            ] = `Bearer ${store.state.session.access}`;
          }
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
      { synchronous: true }
    );

    // Incerteptor over response
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status == 401) {
          let msg = error?.response?.data?.message
            ? error?.response?.data?.message[0]?.message
            : "";
          if (
            msg == "El privilegio 'exp' del token ha expirado" ||
            msg == "Token 'exp' claim has expired" ||
            msg == "Token is invalid or expired" ||
            msg == "El token es inválido o ha expirado"
          ) {
            await refreshToken();
            const config = error.config;
            return axios({
              method: config.method,
              url: config.url,
              data: config.data,
              headers: config.headers,
            });
          } else {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );

    //TO_DO: delete this axiosForm
    axiosForm.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status == 401) {
          let msg = error?.response?.data?.message
            ? error?.response?.data?.message[0]?.message
            : "";
          if (
            msg == "El privilegio 'exp' del token ha expirado" ||
            msg == "Token 'exp' claim has expired" ||
            msg == "Token is invalid or expired" ||
            msg == "El token es inválido o ha expirado"
          ) {
            await refreshToken();
            const config = error.config;
            return axios({
              method: config.method,
              url: config.url,
              data: config.data,
              headers: config.headers,
            });
          } else {
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );
    Vue.prototype.$api = api;
  },
};
